import React from 'react'
import PropTypes from 'prop-types'
import { useLaxElement } from 'use-lax'

import Img from 'gatsby-image'

const propTypes = {
  introTitle: PropTypes.string.isRequired,
  introDescription: PropTypes.string.isRequired,
  introImage: PropTypes.object.isRequired,
  fullWidthMediaTitle: PropTypes.string.isRequired,
  fullWidthVideo: PropTypes.string,
  signoffTitle: PropTypes.string.isRequired,
  signoffDescription: PropTypes.string.isRequired,
  signoffImage: PropTypes.object.isRequired,
}

function About ({ introTitle, introDescription, introImage, fullWidthMediaTitle, fullWidthVideo, signoffTitle, signoffDescription, signoffImage }) {
  return (
    <>
      <div className="c-about c-about--intro">
        <div className="container">
          <div className="row">
            <div className="c-about__wrapper">
              <div className="row">
                <div className="c-about__intro-media-wrapper">
                  <Img
                    alt=""
                    className="c-about__intro-image"
                    fluid={introImage}
                  />
                </div>

                <div className="c-about__intro-content-wrapper">
                  <h2 className="c-about__intro-content-title" dangerouslySetInnerHTML={{ __html: introTitle }}></h2>
                  <p className="c-about__intro-content-description">{introDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c-about c-about--full-width-media">
        <div className="container">
          <div className="row">
            <div className="c-about__wrapper">
              <h2 className="c-about__large-media-text">{fullWidthMediaTitle}</h2>
            </div>
          </div>
        </div>
        <video
          className="c-about__video"
          src={fullWidthVideo}
          autoPlay loop muted playsInline>
        </video>
      </div>

      <div className="c-about c-about--signoff">
        <div className="container">
          <div className="row">
            <div className="c-about__wrapper">
              <div className="row">
                <div className="c-about__signoff-content-wrapper">
                  <div className="c-about__signoff-content">
                    <h2 className="c-about__signoff-content-title">{signoffTitle}</h2>
                    <p className="c-about__signoff-content-description">{signoffDescription}</p>
                  </div>
                </div>

                <div className="c-about__signoff-media-wrapper" data-lax-grayscale_large="1000 100, 450 100, 0 0" data-lax-anchor="self" ref={useLaxElement()}>
                  <Img
                    alt=""
                    className="c-about__signoff-image"
                    fluid={signoffImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

About.propTypes = propTypes

export default About
