import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  decorativeText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

function CharityFeatured ({ buttonText, buttonUrl, decorativeText, description, title }) {
  return (
    <section className="c-charity-featured">
      <div className="container">
        <div className="row">
          <div className="c-charity-featured__decorative-text-wrapper">
            <span className="c-charity-featured__decorative-text">{decorativeText}</span>
          </div>

          <div className="c-charity-featured__content-wrapper">
            <h2 className="c-charity-featured__title">{title}</h2>
            <p className="c-charity-featured__description">{description}</p>
            <Link className="c-charity-featured__btn c-btn c-btn--large c-btn--primary" to={buttonUrl}>{buttonText}</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

CharityFeatured.propTypes = propTypes

export default CharityFeatured
