import React from 'react'
import { graphql } from 'gatsby'
import lax from 'lax.js'
import { useLax } from 'use-lax'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import MastheadVideo from '../components/mastheadvideo/mastheadvideo'
import Newsletter from '../components/newsletter/newsletter'
import About from '../components/about/about'
import CastFeatured from '../components/castfeatured/castfeatured'
import CharityFeatured from '../components/charityfeatured/charityfeatured'
import Vendor from '../components/vendor/vendor'

import aboutVideo from '../videos/about-fear.mp4'

function IndexPage ({ data }) {
  if (typeof document !== 'undefined') {
    // forEach polyfill as IE11 doesn't support it and useLax uses it
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window
        for (let i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this)
        }
      }
    }

    useLax()
    lax.setup({
      breakpoints: { medium: 768, large: 992 }
    })
  }

  return (
    <Layout>
      <SEO
        title="England Cricket"
      />
      <article className="t-home">
        <MastheadVideo
          strapline="The official website of The Edge Film 2019, this is test cricket as you've never seen it before."
          title="The Edge Film 2019"
          video1="https://www.youtube.com/embed/z4jf9_HnBnk"
        />
        <div className="t-home__main">
          <Vendor />
          <Newsletter/>
          <About
            ONE OF THE MOST INSPIRATIONAL SPORTING STORIES YOU WILL EVER SEE
            introTitle="No England team rose higher... <br/>and fell harder"
            introDescription="The Edge Film is a compelling, funny and emotional insight into a band of brothers’ rise to the top, their unmatched achievements and the huge toll it would take.
            One of the toughest sports on the planet, and psychologically perhaps the most challenging,
            The Edge Film explores the ruthless intensity of the game, the impact it can have on players’ mental health and the extreme price of success."
            introImage={data.aboutIntroImage.childImageSharp.fluid}
            fullWidthMediaTitle="FEAR"
            fullWidthVideo={aboutVideo}
            signoffTitle="This is test cricket like you've never seen it before."
            signoffDescription="Between 2009 and 2013, the England Test cricket team rose from the depths of the rankings to become the first and
            only English side to reach world number one since ICC records began. Captain Andrew Strauss and coach Andy Flower took over a team including
            some of the true greats of the English game and transformed them into a phenomenal winning machine before the pressure and scrutiny began to fracture mind, body and soul."
            signoffImage={data.aboutSignoffImage.childImageSharp.fluid}
          />
          <CastFeatured
            btnText="View full cast"
            btnUrl="/cast-and-crew"
            description="Featuring incredible unseen footage from the period and compelling new interviews from star players and coaching staff including: Andrew Strauss, Kevin Pietersen,
            Sir Alastair Cook, James Anderson, Stuart Broad, Graeme Swann, Jonathan Trott and Andy Flower, The Edge Film reveals the team’s intense and often hilarious pursuit of success."
            image={data.castFeaturedImage.childImageSharp.fluid}
            subtitle="The team helped redefine what success meant."
          />
          <CharityFeatured
            title="The Edge Film is proud to support Heads Together and the Ruth Strauss Foundation."
            description="Heads Together is a mental health initiative spearheaded by The Duke and Duchess of Cambridge and The Duke of Sussex,
            which combines a campaign to tackle stigma and change the conversation on mental health with fundraising for a series of innovative new mental health services.
            The Ruth Strauss Foundation provides grants to fund research into rare forms of lung cancer to provide emotional, psychological and wellbeing support to patients and their families."
            buttonText="More info"
            buttonUrl="/charity-partners"
            decorativeText="CHARITY PARTNERS"
          />
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    aboutIntroImage: file(relativePath: { eq: "home/intro-movie-poster.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutSignoffImage: file(relativePath: { eq: "home/signoff-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    castFeaturedImage: file(relativePath: { eq: "cast/cast-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
