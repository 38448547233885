import React from 'react'
import { navigate } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Newsletter extends React.Component {
  state = {
    FNAME: null,
    email: null,
    errorMessage: null,
  }

  _handleChange = e => {
    this.setState({
      [(e.target.type === 'checkbox') ? `'${ e.target.name }'` : `${ e.target.name }`]: e.target.value,
    })
  }

  _setErrorMessage (errorMessage) {
    this.setState(() => {
      return { errorMessage: errorMessage }
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result === 'success') {
          if (this.state.POSITION === 'Batter') {
            navigate('/thank-you-batter/')
          } else if (this.state.POSITION === 'Bowler') {
            navigate('/thank-you-bowler/')
          } else if (this.state.POSITION === 'All-rounder') {
            navigate('/thank-you-all-rounder/')
          }
        } else {
          this._setErrorMessage(msg)
        }
      })
  }

  render () {
    const { errorMessage } = this.state

    return (
      <div className="c-newsletter">
        <div className="container">
          <div className="row">
            <div className="c-newsletter__content-container">
              <h2 className="c-newsletter__title">Join the team and receive our regular email updates.</h2>
              <p className="c-newsletter__description">Add your position to receive a proper welcome to the team.</p>
            </div>

            <div className="c-newsletter__form-container">
              <p className="c-newsletter__form-message" dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
              <form className="c-newsletter__form c-form" onSubmit={this._handleSubmit}>
                <div className="c-newsletter__primary-container">
                  <div className="c-newsletter__input-field">
                    <label className="c-newsletter__form-label c-form__label" htmlFor="newsletter-fname">First name</label>
                    <input className="c-form__textbox" name="FNAME" onChange={this._handleChange} id="newsletter-fname" placeholder="Name" required type="text" />
                  </div>

                  <div className="c-newsletter__input-field">
                    <label className="c-newsletter__form-label c-form__label" htmlFor="newsletter-email">Email address</label>
                    <input className="c-form__textbox" name="email" onChange={this._handleChange} id="newsletter-email" placeholder="Email address" required type="email" />
                  </div>
                </div>

                <div className="c-newsletter__secondary-container">
                  <div className="c-newsletter__radio-group">
                    <strong className="c-newsletter__radio-title">What position would you choose?</strong>

                    <div className="c-form__radio-field">
                      <input type="radio" value="Batter" name="POSITION" id="radio1" className="c-form__radio" onChange={this._handleChange} />
                      <label htmlFor="radio1" className="c-form__label">Batter</label>
                    </div>

                    <div className="c-form__radio-field">
                      <input type="radio" value="Bowler" name="POSITION" id="radio2" className="c-form__radio" onChange={this._handleChange} />
                      <label htmlFor="radio2" className="c-form__label">Bowler</label>
                    </div>

                    <div className="c-form__radio-field">
                      <input type="radio" value="All-rounder" name="POSITION" id="radio3" className="c-form__radio" onChange={this._handleChange} />
                      <label htmlFor="radio3" className="c-form__label">All-rounder</label>
                    </div>
                  </div>

                  <button className="c-newsletter__btn c-btn" type="submit">Sign up</button>
                </div>
              </form>
              <p className="c-newsletter__form-terms">
                We promise to only use your information so that we can provide you with interesting information about our productions.<br/>
                For more information visit our <a href="/privacy-policy">Privacy Policy</a>.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
