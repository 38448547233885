import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLaxElement } from 'use-lax'

import Img from 'gatsby-image'

const propTypes = {
  btnText: PropTypes.string.isRequired,
  btnUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
}

function CastFeatured ({ btnText, btnUrl, description, image, subtitle }) {
  return (
    <div className="c-cast-featured">
      <div className="container">
        <div className="row">
          <h2 className="c-cast-featured__title">ISOLATION</h2>
          <div className="c-cast-featured__title-letters-3" role="presentation" data-lax-preset_large="driftRight-50" ref={useLaxElement()}>
            <span>I</span>
            <span>S</span>
            <span>O</span>
            <span>L</span>
          </div>

          <div className="c-cast-featured__content-wrapper">
            <h3 className="c-cast-featured__subtitle">{subtitle}</h3>
            <p className="c-cast-featured__description">{description}</p>
            <Link className="c-cast-featured__btn c-btn c-btn--large" to={btnUrl}>{btnText}</Link>
          </div>

          <div className="c-cast-featured__title-letters-1" role="presentation" data-lax-preset_large="driftRight-50" ref={useLaxElement()}>L</div>

          <div className="c-cast-featured__image-wrapper" data-lax-grayscale_large="1000 100, 100 100, -100 0" data-lax-anchor="self" ref={useLaxElement()}>
            <Img
              alt=""
              className="c-cast-featured__image"
              fluid={image}
            />
          </div>

          <div className="c-cast-featured__title-letters-5" role="presentation" data-lax-preset_large="driftLeft-50" ref={useLaxElement()}>
            <span>A</span>
            <span>T</span>
            <span>I</span>
            <span>O</span>
            <span>N</span>
          </div>
        </div>
      </div>
    </div>
  )
}

CastFeatured.propTypes = propTypes

export default CastFeatured
