import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const propTypes = {
  strapline: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video1: PropTypes.string.isRequired,
}

function MastheadVideo ({ strapline, title, video1 }) {
  const [isVideo1Open, setIsVideo1Open] = useState(false)
  const openVideo1Class = isVideo1Open ? 'is-active' : ''
  let video1Iframe

  if (isVideo1Open) {
    video1Iframe = <iframe className="c-masthead-video__video" src={video1} allowFullScreen></iframe>
  } else {
    video1Iframe = ''
  }

  function toggleVideo1 () {
    setIsVideo1Open(!isVideo1Open)
  }

  return (
    <div className="c-masthead-video">
      <div className="c-masthead-video__container">
        <div className="container">
          <div className="c-masthead-video__content-row row">
            <div className="c-masthead-video__title-wrapper">
              <h1 className="c-masthead-video__title">{title}</h1>
            </div>
            <div className="c-masthead-video__strapline-wrapper">
              <p className="c-masthead-video__strapline" dangerouslySetInnerHTML={{ __html: strapline }}></p>
            </div>
            <div className="c-masthead-video__play-video-wrapper">
              <button className="c-masthead-video__play-video-btn c-btn c-btn--primary-fill c-btn--large" onClick={toggleVideo1} type="button">Watch the Official Trailer</button>
              <Link className="c-masthead-video__play-video-btn c-btn c-btn--primary-fill c-btn--large" to="/merchandise">Buy the Official Soundtrack</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`c-masthead-video__video-overlay ${ openVideo1Class }`}>
        <button className="c-masthead-video__video-close-btn" onClick={toggleVideo1} type="button"><i className="icon-close"></i></button>
        <div className="container">
          <div className="row">
            <div className="c-masthead-video__video-overlay-wrapper">
              <div className="c-masthead-video__video-overlay-container">
                {video1Iframe}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MastheadVideo.propTypes = propTypes

export default MastheadVideo
